import React, { FC } from 'react'

import router from '../../router'

import * as SC from './styled'

type LinkProps = {
  children: React.ReactNode
  className?: string
  link?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  route?: string
  routeParams?: { [key: string]: string }
  rel?: string
  onClick?: () => void
}

const Link: FC<LinkProps> = ({
  children,
  className,
  link,
  target,
  title,
  role,
  tabIndex,
  route,
  routeParams = {},
  rel,
  onClick = () => undefined,
}) => {
  const renderDefault = () => (
    <SC.LinkA
      className={className}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
      title={title}
      aria-label={title}
      href="javascript:void(0)"
    >
      {children}
    </SC.LinkA>
  )

  const renderAsLink = () => {
    if (link) {
      return (
        <SC.LinkA
          className={className}
          title={title}
          href={link}
          aria-label={title}
          target={target || '_blank'}
          onClick={onClick}
          rel={rel || 'noreferrer'}
          role={role}
          tabIndex={tabIndex}
        >
          {children}
        </SC.LinkA>
      )
    }

    return (
      <SC.LinkGatsby
        to={router(route!, routeParams)}
        className={className}
        title={title}
        aria-label={title}
        onClick={onClick}
        role={role}
        tabIndex={tabIndex}
        target={target}
      >
        {children}
      </SC.LinkGatsby>
    )
  }

  return <>{link || route ? renderAsLink() : renderDefault()}</>
}

export default Link
