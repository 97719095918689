import React, { FC, Ref } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon'

import * as SC from './styled'

export type CountdownProps = {
  className?: string
  currentCount: number | null
  forwardRef?: Ref<HTMLDivElement>
}

const Countdown: FC<CountdownProps> = ({ className, currentCount, forwardRef }) => {
  return (
    <SC.Countdown className={className} ref={forwardRef}>
      <SC.CountNumber className={cx({ active: currentCount === 3 })}>3</SC.CountNumber>
      <SC.CountNumber className={cx({ active: currentCount === 2 })}>2</SC.CountNumber>
      <SC.CountNumber className={cx({ active: currentCount === 1 })}>1</SC.CountNumber>
      <SC.CameraIcon className={cx({ active: currentCount === 0 })} icon={Icons.camera} />
    </SC.Countdown>
  )
}

export default Countdown
