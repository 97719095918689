import React, { ChangeEvent, FC, useCallback } from 'react'

import { Icons } from '../Icon'
import { colors } from '../../theme'

import * as SC from './styled'

export type SelectorProps = {
  className?: string
  options: { value: string; label: string }[]
  value?: string
  onChange?: (value: string) => void
}

const Selector: FC<SelectorProps> = ({ className, value, options, onChange }) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onChange?.(e.target.value)
    },
    [onChange]
  )

  return (
    <SC.Container className={className}>
      <SC.Select value={value} onChange={handleOnChange} autoFocus={false}>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SC.Select>
      <SC.Icon icon={Icons.arrowDown} color={colors.pureWhite} />
    </SC.Container>
  )
}

export default Selector
