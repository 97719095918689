import styled from 'styled-components'

import { colors, mq, sizes, textStyles } from '../../theme'

export const ActionButton = styled.button<{ isActive: boolean; locale: string }>`
  ${textStyles.MontserratRegular18Up};
  background-color: transparent;
  color: ${colors.turquoise};
  padding: 0.6rem 0;
  cursor: pointer;
  border-bottom: ${colors.turquoise} solid 0.2rem;
  width: ${({ locale }) =>
    locale === 'vn' ||
    locale === 'jp' ||
    locale === 'ar' ||
    locale === 'th' ||
    locale === 'ru' ||
    locale === 'zh-hk' ||
    locale === 'zh-cn' ||
    locale === 'ko'
      ? 'auto'
      : 'min-content'};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratRegular32Up};
    padding: 1.2rem 0;
  }
`
