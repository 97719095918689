import React, { FC, memo, useCallback, useEffect, useRef } from 'react'
import { gsap, Power1 } from 'gsap'

import type { StickersPixi } from '../../components/StickersCanvas/StickersPixi'

import * as SC from './styled'

export type StickersStepTemplateProps = {
  isPodium: boolean
  validateLabel: string
  mainPhoto?: string
  onPhotoValidate: (url: string) => void
  stickers: { url: string; id: string }[]
  stickersText: string
  onSelectorStickerClick: () => void
  onSelectorUndoClick: () => void
  onSelectorDeleteClick: () => void
}

const StickersStepTemplate: FC<StickersStepTemplateProps> = ({
  isPodium,
  validateLabel,
  mainPhoto,
  onPhotoValidate,
  stickers,
  stickersText,
  onSelectorStickerClick,
  onSelectorDeleteClick,
  onSelectorUndoClick,
}) => {
  // REFS

  // pixi
  const $stickersPixi = useRef<StickersPixi | null>(null)

  // dom
  const $stickersStepTemplate = useRef<HTMLDivElement>(null)
  const $bottomContainer = useRef<HTMLDivElement>(null)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.4 })

    tl.to($stickersStepTemplate.current, {
      duration: 0.8,
      opacity: 1,
      ease: Power1.easeInOut,
    }).to(
      $bottomContainer.current,
      {
        duration: 0.8,
        opacity: 1,
        ease: Power1.easeInOut,
      },
      '<+0.6'
    )
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ onComplete: completeCallback })
    tl.to($stickersStepTemplate.current, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeInOut,
    })
  }, [])

  // HANDLERS

  const handleOnStickersPixi = useCallback((pixi: StickersPixi) => {
    $stickersPixi.current = pixi
  }, [])

  const handleOnStickerClick = useCallback(
    (id: string) => {
      $stickersPixi.current?.addSticker(id)
      onSelectorStickerClick?.()
    },
    [onSelectorStickerClick]
  )

  const handleOnUndoClick = useCallback(() => {
    $stickersPixi.current?.undo()
    onSelectorUndoClick?.()
  }, [onSelectorUndoClick])

  const handleOnDeleteClick = useCallback(() => {
    $stickersPixi.current?.deleteSticker()
    onSelectorDeleteClick?.()
  }, [onSelectorDeleteClick])

  const handleOnValidateClick = useCallback(() => {
    exitAnimation(() => {
      const canvas = $stickersPixi.current?.validatePhoto()
      // transform canvas to image
      onPhotoValidate(canvas.toDataURL('image/jpeg', 1.0))
    })
  }, [exitAnimation, onPhotoValidate])

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RETURN

  return (
    <SC.StickersStepTemplate ref={$stickersStepTemplate}>
      <SC.Box>
        <SC.Canvas
          isPodium={isPodium}
          onStickersPixi={handleOnStickersPixi}
          mainPhoto={mainPhoto}
          stickers={stickers}
        />
        <SC.BottomContainer ref={$bottomContainer}>
          <SC.StickersSelector
            stickers={stickers}
            text={stickersText}
            onStickerClick={handleOnStickerClick}
            onUndoClick={handleOnUndoClick}
            onDeleteClick={handleOnDeleteClick}
          />
          <SC.Button text={validateLabel} onClick={handleOnValidateClick} />
        </SC.BottomContainer>
      </SC.Box>
    </SC.StickersStepTemplate>
  )
}

export default memo(StickersStepTemplate)
