import React, { FC, memo } from 'react'

import logo from './images/logo.png'
import * as SC from './styled'

export type NotFoundTemplateProps = {
  backgroundImage: string
  title: string
  text: string
  link: string
  linkLabel: string
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = ({
  backgroundImage,
  title,
  text,
  link,
  linkLabel,
}) => (
  <SC.NotFoundTemplate backgroundImage={backgroundImage}>
    <SC.ColorOverlay />
    <SC.ColorGradient />
    <SC.Logo src={logo} />
    <SC.Content>
      <SC.Title>{title}</SC.Title>
      <SC.Text>{text}</SC.Text>
      <SC.LinkDiscorer route={link}>{linkLabel}</SC.LinkDiscorer>
    </SC.Content>
  </SC.NotFoundTemplate>
)

export default memo(NotFoundTemplate)
