import styled from 'styled-components'
import Loadable from '@loadable/component'

import { mq, sizes, vh } from '../../theme'
import ActionButton from '../../components/ActionButton'
import StickersSelectorComponent from '../../components/StickersSelector'

const StickersCanvas = Loadable(() => import('../../components/StickersCanvas'))

export const StickersStepTemplate = styled.div`
  opacity: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${vh(100)};
`

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 100%;

  ${mq(sizes.tablet)} {
    width: auto;
  }
`

export const Canvas = styled(StickersCanvas)`
  position: relative;
  height: calc(100% - 23rem);
  max-height: ${({ isPodium }) =>
    isPodium ? 'calc((3 / 2) * (100vw - 8rem))' : 'calc((16 / 9) * (100vw - 20vw))'};

  ${mq(sizes.tablet)} {
    height: calc(100% - 45rem);
  }

  & > canvas {
    height: 100%;
  }
`

// Bottom

export const BottomContainer = styled.div`
  opacity: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0 1rem 0;

  ${mq(sizes.tablet)} {
    margin: 4rem 0 4rem 0;
  }
`

export const StickersSelector = styled(StickersSelectorComponent)`
  width: 80%;

  ${mq(sizes.tablet)} {
    width: 100%;
  }
`

export const Button = styled(ActionButton)`
  margin-top: 1.5rem;

  ${mq(sizes.tablet)} {
    margin-top: 5rem;
  }
`
