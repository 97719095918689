import React, { FC, memo } from 'react'

import { colors } from '../../theme'

import flower1 from './images/flower1.png'
import flower2 from './images/flower2.png'
import flower3 from './images/flower3.png'
import logo from './images/logo.png'
import * as SC from './styled'

export type WebFallbackTemplateProps = {
  backgroundImage: string
  title: [string, string?]
  text: string
  qrCodeUrl?: string
}

const WebFallbackTemplate: FC<WebFallbackTemplateProps> = ({
  backgroundImage,
  title,
  text,
  qrCodeUrl,
}) => (
  <SC.WebFallbackTemplate backgroundImage={backgroundImage}>
    <SC.ColorOverlay />
    <SC.ColorGradient />
    <SC.Logo src={logo} />
    <SC.Content>
      <SC.Title>
        {title[0]}
        <br />
        {title[1]}
      </SC.Title>
      <SC.Text>{text}</SC.Text>
      {qrCodeUrl && <SC.QrCode url={qrCodeUrl} color={colors.pureWhite} />}
    </SC.Content>
    <SC.Flowers>
      <SC.Flower1 src={flower1} />
      <SC.Flower2 src={flower2} />
      <SC.Flower3 src={flower3} />
    </SC.Flowers>
  </SC.WebFallbackTemplate>
)

export default memo(WebFallbackTemplate)
