import * as controllers from '../Http/Controllers'

export type Route = {
  component: any
  path: string
  name: string
}

export type Routes = {
  root: Route
  legal: Route
  notFound: Route
}

const routes: Routes = {
  root: {
    component: controllers.RootController,
    path: '/',
    name: 'root',
  },
  legal: {
    component: controllers.LegalController,
    path: '/legal',
    name: 'legal',
  },
  notFound: {
    component: controllers.NotFoundController,
    path: '/404',
    name: '404',
  },
}

export default routes
