import React, { FC, useCallback } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'

import * as SC from './styled'

export type ActionButtonProps = {
  className?: string
  id?: string
  text: string
  isActive?: boolean
  isDisabled?: boolean
  onClick?: (id?: string) => void
}

const ActionButton: FC<ActionButtonProps> = ({
  className,
  id,
  text,
  isActive = false,
  isDisabled = false,
  onClick = null,
}) => {
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    if (!isDisabled && onClick) {
      onClick(id)
    }
  }, [id, isDisabled, onClick])

  return (
    <SC.ActionButton
      className={className}
      onClick={handleOnClick}
      isActive={isActive}
      disabled={isDisabled}
      locale={intl.locale}
    >
      {text}
    </SC.ActionButton>
  )
}

export default ActionButton
