import styled from 'styled-components'

import { colors, mq, sizes, textStyles } from '../../theme'
import Icon from '../Icon'

export const Countdown = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CountNumber = styled.div`
  ${textStyles.MontserratRegular24Up};
  color: ${colors.turquoiseLight};

  transition: color 0.2s ease-in-out;

  &.active {
    color: ${colors.turquoise};
    ${textStyles.MontserratSemibold24Up};
  }

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratRegular36Up};
    &.active {
      ${textStyles.MontserratSemibold36};
    }
  }
`

export const CameraIcon = styled(Icon)`
  width: 3.5rem;
  color: ${colors.turquoiseLight};
  transition: color 0.2s ease-in-out;
  &.active {
    color: ${colors.turquoise};
  }
  ${mq(sizes.tablet)} {
    width: 6.5rem;
  }
`
