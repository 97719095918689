import styled from 'styled-components'
import Loadable from '@loadable/component'

const QrCodeComponent = Loadable(() => import('../../components/QrCode'))

import { colors, mq, sizes, textStyles, vh } from '../../theme'

export const WebFallbackTemplate = styled.div<{ backgroundImage: string }>`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  color: ${colors.pureWhite};
  width: 100vw;
  height: ${vh(100)};
`

export const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(213, 146, 170, 0.7);
`

export const ColorGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: linear-gradient(rgb(213, 146, 170, 0), ${colors.pink});
`

export const Logo = styled.img`
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translateX(-50%);
  width: 13rem;

  ${mq(sizes.tablet)} {
    width: 20rem;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Title = styled.h1`
  ${textStyles.MontserratMedium18Up};
  font-size: 2rem;
  text-align: center;

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratMedium24Up};
  }
`

export const Text = styled.p`
  ${textStyles.MontserratMedium16};
  font-size: 1.4rem;
  text-align: center;
  max-width: 42rem;
  width: 80%;

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratMedium16};
  }
`

export const QrCode = styled(QrCodeComponent)`
  width: 15rem;
  height: 15rem;
  margin-top: 6.5rem;
`

export const Flowers = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 100%;
  max-width: 120rem;
  transform: translateX(-50%);
`

export const Flower1 = styled.img`
  position: absolute;
  width: 15rem;
  top: 28%;
  left: 93%;
  transform: translate(-50%, -50%);

  ${mq(sizes.tablet)} {
    width: 35rem;
  }
`

export const Flower2 = styled.img`
  position: absolute;
  width: 21rem;
  top: 80%;
  left: 11%;
  transform: translate(-50%, -50%);

  ${mq(sizes.tablet)} {
    width: 42rem;
  }
`

export const Flower3 = styled.img`
  position: absolute;
  width: 14rem;
  top: 85%;
  left: 80%;
  transform: translate(-50%, -50%);

  ${mq(sizes.tablet)} {
    width: 20rem;
  }
`
