import styled from 'styled-components'

import { colors, mq, sizes, textStyles } from '../../theme'

export const FlowersPreview = styled.div`
  display: flex;
  align-items: center;

  ${mq(sizes.tablet)} {
  }
`

export const Text = styled.p`
  position: relative;
  ${textStyles.MontserratSemibold14Up};
  margin-right: auto;
  color: ${colors.turquoise};

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratSemibold24Up};
    margin-right: 8rem;
  }
`
export const TextExtra = styled.span`
  position: absolute;
  left: calc(100% + 0.3em);
  top: 50%;
  transform: translateY(-50%);

  html[lang='jp'] & {
    left: unset;
    right: calc(100% + 0.3em);
  }
`

export const Flower = styled.img<{ backgroundImage?: string }>`
  position: relative;
  background-color: rgb(58, 165, 157, 0.1);
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : 'none'};
  background-size: cover;
  background-position: center;
  width: 5rem;
  margin-left: 1.5rem;

  ${mq(sizes.tablet)} {
    margin-left: 0;
    margin-right: 2.5rem;
    width: 6rem;
  }
`
