import styled from 'styled-components'

import { colors, textStyles, vh, mq, sizes } from '../../theme'
import Link from '../../components/Link'

export const NotFoundTemplate = styled.div<{ backgroundImage: string }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  color: ${colors.pureWhite};
  width: 100vw;
  height: ${vh(100)};
`

export const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(213, 146, 170, 0.7);
`

export const ColorGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`

export const Logo = styled.img`
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translateX(-50%);
  width: 20rem;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 3.8rem 0 3.8rem;
  align-content: center;
  align-items: center;
`

export const Title = styled.h1`
  ${textStyles.MontserratRegular24Up};
  margin: 2.5rem 0 2rem 0;
`

export const Text = styled.p`
  ${textStyles.MontserratRegular14};
  max-width: 42rem;
  margin-bottom: 2.5rem;
  text-align: center;
  ${mq(sizes.desktop)} {
    max-width: 60rem;
  }
`

export const LinkDiscorer = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  padding: 1rem 2rem 1rem 2rem;
  text-decoration: none;
  ${textStyles.MontserratRegular14};
  color: ${colors.pureWhite};
  text-transform: uppercase;
  border: 0.1rem solid ${colors.pureWhite};
`
