import React, { forwardRef, ForwardRefRenderFunction } from 'react'

import Flower1Podium from './images/flower1_podium_preview.png'
import * as SC from './styled'

export type FlowersPreviewProps = {
  className?: string
  text: string
  textExtra?: string
  flowersPhotoUrl: [string?, string?, string?]
}

const flowerImages = [Flower1Podium, Flower1Podium, Flower1Podium]

const FlowersPreview: ForwardRefRenderFunction<HTMLDivElement, FlowersPreviewProps> = (
  { className, text, textExtra, flowersPhotoUrl },
  ref
) => {
  return (
    <SC.FlowersPreview ref={ref} className={className}>
      <SC.Text>
        {text}
        {textExtra && <SC.TextExtra>{textExtra}</SC.TextExtra>}
      </SC.Text>
      {flowerImages.map((flowerImage, index) => (
        <SC.Flower key={index} src={flowerImage} backgroundImage={flowersPhotoUrl[index]} />
      ))}
    </SC.FlowersPreview>
  )
}

export default forwardRef(FlowersPreview)
