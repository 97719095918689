import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { Link as ReactIntlLink } from 'gatsby-plugin-react-intl'

const styles = css``

export const LinkA = styled.a`
  ${styles}
`
// use intl link with gatsby link fallback to prevent Storybook error
export const LinkGatsby = styled(ReactIntlLink || GatsbyLink)`
  ${styles}
`
