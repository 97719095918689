import styled from 'styled-components'
import Lottie from 'react-lottie-player'
import Loadable from '@loadable/component'

import { colors, mq, sizes, textStyles, vh } from '../../theme'
import ActionButton from '../../components/ActionButton'

const QrCodeComponent = Loadable(() => import('../../components/QrCode'))

export const FinalStepTemplate = styled.div`
  opacity: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${vh(100)};
`

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 100%;
  padding: 2rem 0;

  ${mq(sizes.tablet)} {
    padding: 3rem 0 6rem 0;
  }
`

export const RestartText = styled.p`
  opacity: 0;
  ${textStyles.MontserratRegular18Up};
  color: ${colors.turquoise};
  margin-bottom: 1.8rem;
  ${mq(sizes.tablet)} {
    margin-bottom: 3rem;
    ${textStyles.MontserratRegular24Up};
  }
`

export const Photo = styled.img<{ isPodium: boolean }>`
  position: relative;
  min-height: 0;
  height: 100%;
  max-height: ${({ isPodium }) =>
    isPodium ? 'calc((3 / 2) * (100vw - 8rem))' : 'calc((16 / 9) * (100vw - 20vw))'};
  max-width: initial;
  object-fit: contain;
`

// Bottom

export const BottomContainer = styled.div`
  opacity: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.8rem;
  ${mq(sizes.tablet)} {
    width: 60%;
    margin-top: 8rem;
  }
`

export const QrCodeContainer = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
`

export const QrCodeLoader = styled(Lottie)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: absolute;
  transition: opacity 0.3s;
  width: 9rem;
  height: 9rem;
`

export const QrCode = styled(QrCodeComponent)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s 0.1s;
  position: absolute;
  width: 9rem;
  height: 9rem;
  border: 0.2rem solid ${colors.turquoise};
  border-radius: 0.6rem;
  padding: 0.3rem;
`

export const QrCodeText = styled.p`
  ${textStyles.MontserratRegular24Up};
  margin-left: calc(9rem + 3rem);
  color: ${colors.turquoise};
  max-width: 20rem;

`

export const Button = styled(ActionButton)`
  margin-left: auto;
  margin-right: 6rem;
`

export const WebText = styled.p<{ locale: string }>`
  ${textStyles.MontserratRegular14};
  color: ${colors.turquoise};
  margin: auto;
  width: 70%;
  text-align: ${({ locale }) => (locale === 'ar' ? 'end' : 'unset')};
`
