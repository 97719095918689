import React, { FC, memo, useCallback, useState, useRef, useEffect } from 'react'
import { gsap, Power1 } from 'gsap'

import type { FlowersPhotoPixi } from '../../components/FlowersPhotoCanvas/FlowersPhotoPixi'
import isIOS from '../../utils/IsIOS'

import * as SC from './styled'

export type FlowersPhotoStepTemplateProps = {
  isPodium: boolean
  webcamVideo?: HTMLVideoElement
  flowersPreviewText: string
  flowersPreviewPhotoUrls: [string?, string?, string?]
  onPhotoTaken: (photo: { index: number; url: string }) => void
  onEnd: () => void
}

const nbFlower = 3

const FlowersPhotoStepTemplate: FC<FlowersPhotoStepTemplateProps> = ({
  isPodium,
  webcamVideo,
  onPhotoTaken,
  flowersPreviewText,
  flowersPreviewPhotoUrls,
  onEnd,
}) => {
  // REFS

  // pixi
  const $flowersPhotoPixi = useRef<FlowersPhotoPixi | null>(null)

  // dom
  const $flowersPhotoStepTemplate = useRef<HTMLDivElement>(null)
  const $flowersPreview = useRef<HTMLDivElement>(null)

  // STATES

  const [isPixiReady, setIsPixiReady] = useState(false)
  const [countdown, setCountdown] = useState<number | null>(null)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)

  // FUNCTIONS

  const clampNumber = useCallback((number, min, max) => {
    return Math.min(Math.max(number, min), max)
  }, [])

  const startCountDown = useCallback(
    (photoIndex) => {
      const tl = gsap.timeline({ delay: 1.5 })

      const countdownAndCaptureAudio =
        window.countdownAndCaptureAudio || new Audio('/audio/countdownAndCapture.mp3')

      tl.add(() => {
        countdownAndCaptureAudio.play()
      })
        .add(
          () => {
            setCountdown(3)
          },
          isIOS() ? '+=0.5' : '+=0.2'
        )
        .add(() => {
          setCountdown(2)
        }, '+=1')
        .add(() => {
          setCountdown(1)
        }, '+=1')
        .add(() => {
          setCountdown(0)
        }, '+=1')
        .add(() => {
          // take photo
          const canvas = $flowersPhotoPixi.current?.takePhoto(photoIndex)
          // transform canvas to image url
          canvas.toBlob(
            (blob: Blob) => {
              const localUrl = window.URL.createObjectURL(blob)
              onPhotoTaken?.({ index: photoIndex, url: localUrl })
            },
            'image/jpeg',
            0.8
          )
        }, '+=0.3')
        .add(() => {
          setCountdown(null)
          setCurrentPhotoIndex((prevState) => prevState + 1)
        }, '+=0.1')
    },
    [onPhotoTaken]
  )

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.1 })

    const previewText = $flowersPreview.current!.querySelector('p')
    const previewFlowers = $flowersPreview.current!.querySelectorAll('img')

    tl.to($flowersPhotoStepTemplate.current, {
      duration: 0.6,
      opacity: 1,
      ease: Power1.easeInOut,
    }).fromTo(
      [previewText, previewFlowers],
      {
        opacity: 0,
      },
      {
        duration: 0.5,
        opacity: 1,
        stagger: 0.1,
        ease: Power1.easeIn,
      },
      '<+0.2'
    )
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ delay: 0.3, onComplete: completeCallback })

    tl.to($flowersPhotoStepTemplate.current, {
      duration: 0.3,
      opacity: 0,
      ease: Power1.easeInOut,
    })
  }, [])

  // HANDLERS

  const handleOnFlowersPhotoPixi = useCallback((pixi: FlowersPhotoPixi) => {
    $flowersPhotoPixi.current = pixi
    setIsPixiReady(true)
  }, [])

  // EFFECTS

  useEffect(() => {
    if (webcamVideo && isPixiReady) {
      if (currentPhotoIndex < nbFlower) {
        startCountDown(currentPhotoIndex)
        $flowersPhotoPixi.current?.changePhotoIndex(currentPhotoIndex)
      } else {
        exitAnimation(onEnd)
      }
    }
  }, [currentPhotoIndex, exitAnimation, isPixiReady, onEnd, startCountDown, webcamVideo])

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // return

  return (
    <SC.FlowersPhotoStepTemplate ref={$flowersPhotoStepTemplate} isPodium={isPodium}>
      <SC.TopContainer>
        <SC.FlowersPreview
          ref={$flowersPreview}
          text={flowersPreviewText}
          textExtra={`${clampNumber(currentPhotoIndex + 1, 0, nbFlower)}`}
          flowersPhotoUrl={flowersPreviewPhotoUrls}
        />
      </SC.TopContainer>
      <SC.Canvas
        webcamVideoElement={webcamVideo}
        isPodium={isPodium}
        onFlowersPhotoPixi={handleOnFlowersPhotoPixi}
      />
      <SC.BottomContainer>
        <SC.Countdown currentCount={countdown} />
      </SC.BottomContainer>
    </SC.FlowersPhotoStepTemplate>
  )
}

export default memo(FlowersPhotoStepTemplate)
