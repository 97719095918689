import styled from 'styled-components'

import { colors, mq, sizes, textStyles } from '../../theme'
import Icon from '../Icon'

export const StickersSelector = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem 0.6rem 1rem;
  border-bottom: 0.2rem solid ${colors.turquoiseLight};
  ${mq(sizes.tablet)} {
    padding: 0 2rem 1.2rem 1rem;
  }
`

export const Text = styled.p`
  ${textStyles.MontserratRegular18Up};
  color: ${colors.turquoise};
  margin-right: auto;
  ${mq(sizes.tablet)} {
    ${textStyles.MontserratRegular28Up};
  }
`

export const ActionIcon = styled(Icon)`
  cursor: pointer;
  width: 4rem;
  margin-left: 0.5rem;
  color: ${colors.turquoise};
  opacity: 1;
  transition: 0.1s opacity;
  &:active {
    opacity: 0.5;
  }

  ${mq(sizes.tablet)} {
    width: 5rem;
    margin-left: 3rem;
  }
`

export const Stickers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;
  ${mq(sizes.tablet)} {
    margin-top: 3rem;
  }
`

export const Sticker = styled.img`
  cursor: pointer;
  width: 5rem;
  opacity: 1;
  transition: 0.1s opacity;
  &:active {
    opacity: 0.5;
  }
  ${mq(sizes.tablet)} {
    width: 8rem;
  }
`
