import React, { FC, memo, useCallback, useEffect, useRef } from 'react'
import { gsap, Power1 } from 'gsap'

import routes from '../../router/routes'
import { SelectorProps } from '../../components/Selector'

import logo from './images/logo.png'
import * as SC from './styled'
import flowerAnimation from './animations/flower.json'

export type HomepageTemplateProps = {
  backgroundImagePodium: string
  backgroundImageWeb: string
  bottleImage: string
  isPodium: boolean
  flowerText: string
  legalLinkText: string
  onFlowerClick?: () => void
  localesSelector?: SelectorProps
}

const HomepageTemplate: FC<HomepageTemplateProps> = ({
  isPodium,
  backgroundImagePodium,
  backgroundImageWeb,
  bottleImage,
  flowerText,
  legalLinkText,
  localesSelector,
  onFlowerClick,
}) => {
  const $homepageTemplate = useRef<HTMLDivElement>(null)
  const $background = useRef<HTMLDivElement>(null)
  const $bottle = useRef<HTMLImageElement>(null)
  const $flower = useRef<HTMLDivElement>(null)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.3 })

    tl.to($homepageTemplate.current!.children, {
      duration: 0.8,
      opacity: 1,
      ease: Power1.easeInOut,
    })
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ delay: 0.1, onComplete: completeCallback })

    tl.to($flower.current, {
      duration: 0.3,
      opacity: 0,
      ease: Power1.easeInOut,
    })
      .to(
        $flower.current,
        {
          duration: 0.6,
          scale: 3.5,
          ease: Power1.easeInOut,
        },
        '<'
      )
      .to(
        $bottle.current,
        {
          duration: 0.6,
          xPercent: -100,
          ease: Power1.easeInOut,
        },
        '<'
      )
      .to(
        $homepageTemplate.current,
        {
          duration: 0.4,
          opacity: 0,
          ease: Power1.easeInOut,
        },
        '<+0.2'
      )

    tl.timeScale(0.8)
  }, [])

  // HANDLERS

  const handleOnFlowerClick = useCallback(() => {
    exitAnimation(onFlowerClick)
  }, [exitAnimation, onFlowerClick])

  // EFFECTS

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  return (
    <SC.HomeTemplate ref={$homepageTemplate}>
      <SC.Background
        ref={$background}
        backgroundImage={isPodium ? backgroundImagePodium : backgroundImageWeb}
      />
      {!isPodium && <SC.ColorOverlay />}
      <SC.Logo $isPodium={isPodium} src={logo} />
      {localesSelector && <SC.LocalesSelector {...localesSelector} />}
      <SC.Box $isPodium={isPodium}>
        <SC.Flower ref={$flower} $isPodium={isPodium} onClick={handleOnFlowerClick}>
          <SC.FlowerAnimation animationData={flowerAnimation} play $isPodium={isPodium} />
          <SC.FlowerText $isPodium={isPodium} $locale={localesSelector?.value}>
            {flowerText}
          </SC.FlowerText>
        </SC.Flower>
        <SC.Bottle ref={$bottle} src={bottleImage} $isPodium={isPodium} />
        <SC.LegalLink route={routes.legal.path}>{legalLinkText}</SC.LegalLink>
      </SC.Box>
    </SC.HomeTemplate>
  )
}

export default memo(HomepageTemplate)
