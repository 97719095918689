import styled from 'styled-components'
import Loadable from '@loadable/component'

import { colors, textStyles, mq, sizes, vh } from '../../theme'
import CountdownComponent from '../../components/Countdown'
import ActionButton from '../../components/ActionButton'

const MainPhotoCanvas = Loadable(() => import('../../components/MainPhotoCanvas'))

export const MainPhotoStepTemplate = styled.div`
  opacity: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${vh(100)};
`

export const Box = styled.div`
  position: relative;
  margin-bottom: ${vh(12)};
`

// needed for video mix-blend-mode
export const BoxBackground = styled.video`
  position: absolute;
  width: 100vw;
  background-color: white;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
`

export const Canvas = styled(MainPhotoCanvas)`
  position: relative;
  height: ${vh(78)};
  max-height: ${({ isPodium }) =>
    isPodium ? 'calc((3 / 2) * (100vw - 8rem))' : 'calc((16 / 9) * (100vw - 20vw))'};

  & > canvas {
    height: 100%;
  }
`

// Bottle

export const Bottle = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 39%);
  height: 52%;
  filter: drop-shadow(15px 10px 55px rgba(0, 0, 0, 0.25));
  ${mq(sizes.tablet)} {
    transform: translate(-53%, 33%);
    height: 55%;
  }
`

export const BottleButton = styled(Bottle)``

// video
export const SprayVideo = styled.video`
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 99%;
  mix-blend-mode: screen;
  &::-internal-media-controls-overlay-cast-button,
  &::-webkit-media-controls-overlay-play-button {
    display: none;
  }
`

// Bottom

export const BottomContainer = styled.div`
  position: absolute;
  top: calc(100% + 2rem);
  left: 0;
  width: 100%;
  display: flex;
  height: 5rem;
  justify-content: center;
  z-index: 2;

  ${mq(sizes.tablet)} {
    top: calc(100% + 5rem);
  }
`

export const InstructionsText = styled.p`
  position: absolute;
  width: 55%;
  min-width: 19rem;
  margin-left: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.turquoise};
  ${textStyles.MontserratRegular18Up};

  ${mq(sizes.tablet)} {
    margin-left: 5rem;
    ${textStyles.MontserratRegular36Up};
    width: 70%;
  }
`

export const Countdown = styled(CountdownComponent)`
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  opacity: 0;
  width: 15rem;
  margin-top: 1rem;

  ${mq(sizes.tablet)} {
    left: 50%;
    margin-top: 3rem;
    width: 32rem;
  }
`

export const Buttons = styled.div`
  opacity: 0;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;

  ${mq(sizes.tablet)} {
    margin-top: 8rem;
  }
`

export const Button = styled(ActionButton)``

export const Flash = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${colors.pureWhite};
  pointer-events: none;
  opacity: 0;
`
