import React, { FC, memo, useCallback, useState, useRef, RefCallback, useEffect } from 'react'
import { gsap, Power1, Power2 } from 'gsap'

import type { MainPhotoPixi } from '../../components/MainPhotoCanvas/MainPhotoPixi'
import isIOS from '../../utils/IsIOS'

import BottleImage from './images/bottle.png'
import BottleButtonImage from './images/bottleButton.png'
import * as SC from './styled'

export type MainPhotoStepTemplateProps = {
  isPodium: boolean
  webcamVideo?: HTMLVideoElement
  instructionsText: string
  retakeLabel: string
  validateLabel: string
  onBottleClick?: () => void
  onRetakeClick?: () => void
  onPhotoValidate?: (url: string) => void
}

const MainPhotoStepTemplate: FC<MainPhotoStepTemplateProps> = ({
  isPodium,
  webcamVideo,
  instructionsText,
  retakeLabel,
  validateLabel,
  onBottleClick,
  onRetakeClick,
  onPhotoValidate,
}) => {
  // REFS

  // pixi
  const $mainPhotoPixi = useRef<MainPhotoPixi | null>(null)

  // dom
  const $mainPhotoTemplate = useRef<HTMLDivElement>(null)
  const $instructionsText = useRef<HTMLParagraphElement>(null)
  const $countDown = useRef<HTMLDivElement>(null)
  const $bottleImage = useRef<HTMLImageElement>(null)
  const $bottleButtonImage = useRef<HTMLImageElement>(null)
  const $sprayVideo = useRef<HTMLVideoElement>(null)
  const $flash = useRef<HTMLDivElement>(null)
  const $buttons = useRef<HTMLDivElement>(null)
  const $canvas = useRef<HTMLDivElement | null>(null)

  // STATES

  const [countdown, setCountdown] = useState<number | null>(null)

  // FUNCTIONS

  const startCountDown = useCallback(() => {
    const tl = gsap.timeline({ delay: 1, paused: true })
    window.countdownAndCaptureAudio = new Audio('/audio/countdownAndCapture.mp3')

    return tl
      .add(() => {
        window.countdownAndCaptureAudio.play()
      })
      .add(
        () => {
          setCountdown(3)
        },
        isIOS() ? '+=0.5' : '+=0.2'
      )
      .add(() => {
        setCountdown(2)
      }, '+=1')
      .add(() => {
        setCountdown(1)
      }, '+=1')
      .add(() => {
        setCountdown(0)
      }, '+=1')
      .to(
        $flash.current,
        {
          duration: 0.2,
          opacity: 1,
        },
        '+=0.3'
      )
      .set(
        [$countDown.current, $bottleImage.current, $bottleButtonImage.current, $sprayVideo.current],
        {
          opacity: 0,
        }
      )
      .add(() => {
        setCountdown(null)
        // take photo
        $mainPhotoPixi.current?.takePhoto()
      }, '+=0.1')
      .to(
        $flash.current,
        {
          duration: 0.5,
          opacity: 0,
          ease: Power2.easeInOut,
        },
        '+=0.2'
      )
      .to(
        $buttons.current,
        {
          duration: 0.5,
          opacity: 1,
        },
        '+=0.5'
      )
  }, [])

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.6 })

    tl.to($mainPhotoTemplate.current, {
      duration: 0.8,
      opacity: 1,
      ease: Power1.easeInOut,
    })
      .from(
        [$bottleImage.current, $bottleButtonImage.current],
        {
          duration: 0.7,
          left: -window.innerWidth * 0.11,
          opacity: 0,
          ease: Power1.easeOut,
        },
        '-=0.1'
      )
      .add(() => {
        $mainPhotoPixi.current?.animateFlowersIn()
      }, '<+0.3')
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ delay: 0, onComplete: completeCallback })

    tl.to($mainPhotoTemplate.current, {
      duration: 0.4,
      opacity: 0,
    })
  }, [])

  // HANLDERS

  const handleOnMainPhotoPixi = useCallback((pixi: MainPhotoPixi) => {
    $mainPhotoPixi.current = pixi
  }, [])

  const handleOnBottleClick = useCallback(() => {
    onBottleClick?.()
    const tl = gsap.timeline()
    const countDownTimeline = startCountDown()

    const sprayAudio = new Audio('/audio/spray.mp3')

    tl.set($bottleImage.current, { pointerEvents: 'none' })
      .to($instructionsText.current, {
        duration: 0.3,
        opacity: 0,
      })
      .add(() => {
        countDownTimeline.play()
      })
      .to(
        $countDown.current,
        {
          duration: 0.3,
          opacity: 1,
        },
        '<'
      )
      .to(
        $bottleButtonImage.current,
        {
          duration: 0.7,
          yPercent: 2.5,
          ease: Power2.easeOut,
        },
        '<'
      )
      .add(() => {
        $sprayVideo.current?.play()
        sprayAudio.play()
        $sprayVideo.current!.style.opacity = '1'
      }, '<+0.4')
      .to(
        $bottleButtonImage.current,
        {
          duration: 0.8,
          yPercent: 0,
          ease: Power2.easeOut,
        },
        '>+=0.2'
      )
  }, [onBottleClick, startCountDown])

  const handleOnRetakeClick = useCallback(() => {
    onRetakeClick?.()
    const tl = gsap.timeline()

    tl.to([$buttons.current, $canvas.current], {
      duration: 0.5,
      opacity: 0,
    })
      .add(() => {
        $mainPhotoPixi.current?.resetPhoto()
      })
      .to(
        [$bottleImage.current, $bottleButtonImage.current, $canvas.current],
        {
          duration: 0.5,
          opacity: 1,
        },
        '+=0.2'
      )
      .set($bottleImage.current, { pointerEvents: 'auto' })
  }, [onRetakeClick])

  const handleOnValidateClick = useCallback(() => {
    exitAnimation(() => {
      const canvas = $mainPhotoPixi.current?.validatePhoto()
      canvas.toBlob(
        (blob: Blob) => {
          const localUrl = window.URL.createObjectURL(blob)
          onPhotoValidate?.(localUrl)
        },
        'image/jpeg',
        0.8
      )
    })
  }, [exitAnimation, onPhotoValidate])

  const handleCanvasRef: RefCallback<HTMLDivElement> = useCallback((ref) => {
    $canvas.current = ref
  }, [])

  // EFFECT

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RETURN

  return (
    <SC.MainPhotoStepTemplate ref={$mainPhotoTemplate}>
      <SC.Box>
        <SC.BoxBackground />
        <SC.Canvas
          forwardRef={handleCanvasRef}
          webcamVideoElement={webcamVideo}
          isPodium={isPodium}
          onMainPhotoPixi={handleOnMainPhotoPixi}
        />
        <SC.BottomContainer>
          <SC.InstructionsText ref={$instructionsText}>{instructionsText}</SC.InstructionsText>
          <SC.Countdown forwardRef={$countDown} currentCount={countdown} />
          <SC.Buttons ref={$buttons}>
            <SC.Button text={retakeLabel} onClick={handleOnRetakeClick} />
            <SC.Button text={validateLabel} onClick={handleOnValidateClick} />
          </SC.Buttons>
        </SC.BottomContainer>
        <SC.BottleButton
          ref={$bottleButtonImage}
          onClick={handleOnBottleClick}
          src={BottleButtonImage}
        />
        <SC.Bottle ref={$bottleImage} onClick={handleOnBottleClick} src={BottleImage} />
        <SC.SprayVideo
          src={isPodium ? '/video/spray-podium.mp4' : '/video/spray-web.mp4'}
          ref={$sprayVideo}
          muted={true}
          playsInline={true}
          autoPlay={false}
          disableRemotePlayback={true}
        />
      </SC.Box>
      <SC.Flash ref={$flash} />
    </SC.MainPhotoStepTemplate>
  )
}

export default memo(MainPhotoStepTemplate)
