import styled from 'styled-components'

import { colors, mq, sizes, textStyles } from '../../theme'
import IconComponent from '../Icon'

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  border-bottom: ${colors.pureWhite} solid 0.1rem;
  transform: scale(12/16);
  ${mq(sizes.tablet)} {
    transform: scale(1);
  }
`

export const Select = styled.select`
  appearance: none;
  width: 100%;
  ${textStyles.MontserratMedium12};
  font-size: 16px; // hack to prevent zoom on iOS
  color: ${colors.pureWhite};
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  cursor: pointer;
  outline: none;

  ${mq(sizes.tablet)} {
    ${textStyles.MontserratMedium24Up};
    padding: 1rem 3rem 1rem 1rem;
  }
`

export const Icon = styled(IconComponent)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
`
