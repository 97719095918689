import styled from 'styled-components'
import Loadable from '@loadable/component'

import { mq, sizes, vh } from '../../theme'
import ActionButton from '../../components/ActionButton'

const FlowersFinalCanvas = Loadable(() => import('../../components/FlowersFinalCanvas'))

export const FlowersFinalStepTemplate = styled.div`
  opacity: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: ${vh(100)};
`

export const Box = styled.div`
  position: relative;
  display: block;
  margin-bottom: ${vh(12)};
`

export const Canvas = styled(FlowersFinalCanvas)`
  position: relative;
  height: ${vh(78)};
  max-height: ${({ isPodium }) =>
    isPodium ? 'calc((3 / 2) * (100vw - 8rem))' : 'calc((16 / 9) * (100vw - 20vw))'};

  & > canvas {
    height: 100%;
  }
`

// Bottom

export const BottomContainer = styled.div`
  opacity: 0;
  position: absolute;
  top: calc(100% + 2rem);
  left: 0;
  width: 100%;
  display: flex;
  height: 5rem;
  justify-content: center;

  ${mq(sizes.tablet)} {
    top: calc(100% + 5rem);
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;

  ${mq(sizes.tablet)} {
    margin-top: 8rem;
  }
`

export const Button = styled(ActionButton)``
