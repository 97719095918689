import React, { FC, memo, useCallback, useEffect, useRef } from 'react'
import { gsap, Power1 } from 'gsap'

import * as SC from './styled'
import qrCodeAnimation from './animations/qr-code.json'

import { useIntl } from 'gatsby-plugin-react-intl'

export type FinalStepTemplateProps = {
  isPodium: boolean
  restartText: string
  printLabel: string
  photo?: string
  qrCodeUrl?: string
  qrCodeText: string
  webText: string
  canPrint?: boolean
  isPrinting: boolean
  onPrintClick: () => void
  onRestartClick: () => void
}

const FinalStepTemplate: FC<FinalStepTemplateProps> = ({
  isPodium,
  restartText,
  printLabel,
  photo,
  qrCodeUrl,
  qrCodeText,
  webText,
  canPrint = false,
  isPrinting,
  onPrintClick,
  onRestartClick,
}) => {

  const intl = useIntl()
  
  // REFS

  const $finalStepTemplate = useRef<HTMLDivElement>(null)
  const $restartText = useRef<HTMLParagraphElement>(null)
  const $bottomContainer = useRef<HTMLDivElement>(null)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.3 })

    tl.to($finalStepTemplate.current, {
      duration: 0.8,
      opacity: 1,
      ease: Power1.easeInOut,
    }).to(
      [$bottomContainer.current, $restartText.current],
      {
        duration: 0.8,
        opacity: 1,
        ease: Power1.easeInOut,
      },
      '<+0.4'
    )
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ onComplete: completeCallback })
    tl.to($finalStepTemplate.current, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeInOut,
    })
  }, [])

  // HANDLERS

  const handleOnRestartClick = useCallback(() => {
    exitAnimation(onRestartClick)
  }, [exitAnimation, onRestartClick])

  // EFFECTS

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  return (
    <SC.FinalStepTemplate ref={$finalStepTemplate}>
      <SC.Box>
        <SC.RestartText onClick={handleOnRestartClick} ref={$restartText}>
          {restartText}
        </SC.RestartText>
        <SC.Photo isPodium={isPodium} src={photo} />
        <SC.BottomContainer ref={$bottomContainer}>
          {isPodium ? (
            <>
              <SC.QrCodeContainer>
                <SC.QrCodeLoader
                  isVisible={!qrCodeUrl}
                  animationData={qrCodeAnimation}
                  play
                  speed={1}
                  loop
                />
                <SC.QrCode url={qrCodeUrl} isVisible={!!qrCodeUrl} />
                <SC.QrCodeText>{qrCodeText}</SC.QrCodeText>
              </SC.QrCodeContainer>
              <SC.Button
                text={printLabel}
                onClick={onPrintClick}
                isDisabled={!canPrint || isPrinting}
              />
            </>
          ) : (
            <SC.WebText locale={intl.locale}>{webText}</SC.WebText>
          )}
        </SC.BottomContainer>
      </SC.Box>
    </SC.FinalStepTemplate>
  )
}

export default memo(FinalStepTemplate)
