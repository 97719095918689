import { useCallback, useEffect, useState } from 'react'

type WindowSize = { width: number; height: number }

export const useWindowSize = (callBack?: (size: WindowSize) => void): WindowSize => {
  const [size, setSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })

  const handleResize = useCallback(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    callBack?.({ width: window.innerWidth, height: window.innerHeight })
  }, [callBack])

  useEffect(() => {
    handleResize()
  }, [handleResize])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return size
}
