import styled, { css } from 'styled-components'
import Lottie from 'react-lottie-player'

import { colors, textStyles, vh } from '../../theme'
import Link from '../../components/Link'
import Selector from '../../components/Selector'

export const HomeTemplate = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  color: ${colors.pureWhite};
  width: 100vw;
  height: ${vh(100)};
  background-color: ${colors.pink};

  & > * {
    opacity: 0;
  }
`

export const Background = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
`

export const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(213, 146, 170, 0.5);
`

export const Logo = styled.img<{ $isPodium: boolean }>`
  position: absolute;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.3));
  ${({ $isPodium }) =>
    $isPodium
      ? css`
          bottom: 3%;
          right: 3vh;
          width: 25%;
        `
      : css`
          left: 50%;
          top: 7%;
          transform: translateX(-50%);
          width: 12.5rem;
        `};
`

export const Box = styled.div<{ $isPodium: boolean }>`
  position: relative;
  max-width: ${({ $isPodium }) => ($isPodium ? 'auto' : '450px')};
  height: 100%;
  width: 100%;
`

export const Bottle = styled.img<{ $isPodium: boolean }>`
  position: absolute;
  filter: drop-shadow(20px 35px 65px rgba(0, 0, 0, 0.3));
  pointer-events: none;
  ${({ $isPodium }) =>
    $isPodium
      ? css`
          bottom: 4%;
          left: 5%;
          height: 71%;
        `
      : css`
          bottom: 3.5rem;
          left: 2%;
          height: 41rem;
          max-height: ${vh(70)};
        `};
`

export const Flower = styled.div<{ $isPodium: boolean }>`
  position: absolute;
  cursor: pointer;

  ${({ $isPodium }) =>
    $isPodium
      ? css`
          top: 15%;
          right: 3%;
        `
      : css`
          bottom: 35%;
          right: 1%;
        `}
`

export const FlowerAnimation = styled(Lottie)<{ $isPodium: boolean }>`
  transform: rotate(330deg);
  ${({ $isPodium }) =>
    $isPodium
      ? css`
          width: 72vw;
        `
      : css`
          width: 31rem;
          max-width: ${vh(48)};
        `}
`

export const FlowerText = styled.p<{ $isPodium: boolean; $locale?: string }>`
  position: absolute;
  text-align: center;
  top: 49%;
  left: 53%;
  font-size: ${({ $locale, $isPodium }) =>
    $locale === 'id' ? ($isPodium ? '2.8rem!important' : '2rem !important ') : 'initial'};
  transform: translate(-50%, -50%);
  width: 60%;
  color: ${colors.pureWhite};
  ${({ $isPodium }) =>
    $isPodium ? textStyles.MontserratMedium30Up : textStyles.MontserratMedium18Up}
  ${({ $isPodium, $locale }) =>
    !$isPodium &&
    $locale === 'jp' &&
    css`
      font-size: 1.6rem;
    `}
`

export const LegalLink = styled(Link)`
  position: absolute;
  text-align: center;
  bottom: 1.2rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.pureWhite};
  ${textStyles.MontserratRegular14};
  font-size: 1.2rem;
  outline: none;
`

export const LocalesSelector = styled(Selector)`
  position: absolute !important;
  right: 6%;
  top: 4%;
  z-index: 100;
`
