import React, { FC, memo } from 'react'

import * as icons from './icons'
import * as SC from './styled'

export enum Icons {
  arrowDown = 'arrowDown',
  camera = 'camera',
  socialFacebook = 'socialFacebook',
  socialInstagram = 'socialInstagram',
  socialTwitter = 'socialTwitter',
  socialYoutube = 'socialYoutube',
  trash = 'trash',
  undo = 'undo',
}

export type IconProps = {
  className?: string
  icon: Icons
  color?: string
  onClick?: () => void
}

const Icon: FC<IconProps> = ({ className, icon, color = 'black', onClick }) => {
  const { viewBox, id } = icons[icon]
  const symbolId = id?.replace('-usage', '') ?? ''

  return (
    <SC.Svg className={className} color={color} viewBox={viewBox} onClick={onClick}>
      <use xlinkHref={`#${symbolId}`} href={`#${symbolId}`} />
    </SC.Svg>
  )
}

export default memo(Icon)
