import React, { FC, memo, Fragment } from 'react'

import logo from './images/logo.png'
import * as SC from './styled'

export type LegalTemplateProps = {
  backgroundImage: string
  title: string
  contents?: { title: string; content: string }[]
  link: string
  linkLabel: string
}

const LegalTemplate: FC<LegalTemplateProps> = ({
  backgroundImage,
  title,
  contents,
  link,
  linkLabel,
}) => (
  <SC.LegalTemplate>
    <SC.BackgroundImage backgroundImage={backgroundImage} />
    <SC.ColorOverlay />
    <SC.ColorGradient />
    <SC.Logo src={logo} />
    <SC.Box>
      <SC.Title>{title}</SC.Title>
      <SC.Contents>
        {contents?.map((c) => (
          <Fragment key={c.title}>
            <SC.Subtitle>{c.title}</SC.Subtitle>
            <SC.Content dangerouslySetInnerHTML={{ __html: c.content }} />
          </Fragment>
        ))}
      </SC.Contents>
      <SC.LinkExperience route={link}>{linkLabel}</SC.LinkExperience>
    </SC.Box>
  </SC.LegalTemplate>
)

export default memo(LegalTemplate)
