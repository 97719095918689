import styled from 'styled-components'
import Loadable from '@loadable/component'

import { mq, sizes, vh } from '../../theme'
import CountdownComponent from '../../components/Countdown'
import FlowersPreviewComponent from '../../components/FlowersPreview'

const FlowersPhotoCanvas = Loadable(() => import('../../components/FlowersPhotoCanvas'))

export const FlowersPhotoStepTemplate = styled.div<{ isPodium: boolean }>`
  opacity: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
  height: ${vh(100)};
  padding: ${({ isPodium }) => (isPodium ? '8.5rem 0' : '4.5rem 0')};
`

// Top

export const TopContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const FlowersPreview = styled(FlowersPreviewComponent)`
  position: relative;
  width: 80%;

  ${mq(sizes.tablet)} {
    width: 90%;
  }
`

export const Canvas = styled(FlowersPhotoCanvas)`
  position: relative;
  width: 95%;
  max-width: min(85rem, 65vh);
  & > canvas {
    width: 100%;
  }
`

// Bottom

export const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Countdown = styled(CountdownComponent)`
  width: 15rem;

  ${mq(sizes.tablet)} {
    width: 32rem;
  }
`
