import styled from 'styled-components'

import { colors, textStyles, vh, mq, sizes } from '../../theme'
import Link from '../../components/Link'

export const LegalTemplate = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.pureWhite};
  width: 100vw;
  max-width: 100%;
  min-height: ${vh(100)};
  padding-top: 4rem;
  padding-bottom: 5rem;
`

export const BackgroundImage = styled.div<{ backgroundImage: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: ${vh(100)};
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  pointer-events: none;
`

export const ColorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: ${vh(100)};
  pointer-events: none;
  background-color: rgba(213, 146, 170, 0.7);
`

export const ColorGradient = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: ${vh(100)};
  pointer-events: none;
  background: linear-gradient(rgb(213, 146, 170, 0), ${colors.pink});
`

export const Logo = styled.img`
  position: relative;
  width: 14.7rem;
  margin-bottom: 4.1rem;

  ${mq(sizes.desktop)} {
    width: 12.5rem;
    position: absolute;
    top: 4rem;
    left: 4rem;
  }
`

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 42rem;
  margin: 0 3.8rem 0 3.8rem;
  align-items: center;

  ${mq(sizes.tablet)} {
    max-width: 60rem;
  }
`

export const Title = styled.h1`
  ${textStyles.MontserratRegular24Up};
  align-self: flex-start;
  margin: 0 0 2.5rem 0;
`

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
`

export const Subtitle = styled.h2`
  ${textStyles.MontserratMedium18Up};
  text-align: left;
`

export const Content = styled.div`
  ${textStyles.MontserratRegular14};
  text-align: left;
  margin-bottom: 2rem;

  & p {
    margin-bottom: 1.5rem;
  }

  & a {
    color: inherit;
    text-decoration: underline;
  }
`

export const LinkExperience = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  padding: 1rem 2rem 1rem 2rem;
  text-decoration: none;
  ${textStyles.MontserratRegular14};
  color: ${colors.pureWhite};
  text-transform: uppercase;
  border: 0.1rem solid ${colors.pureWhite};
`
