import React, { FC, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'gatsby-plugin-react-intl'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'

import { GlobalStyles, themes } from '../../theme'
import Seo, { SeoProps } from '../Seo'
import DefaultHead from '../DefaultHead'
import { selectors } from '../../redux'
import { useWindowSize } from '../../hooks/useWindowSize'
import * as configuration from '../../configuration'


type AppProps = {
  seo: SeoProps
}

const App: FC<AppProps> = (props) => {
  const { children, seo } = props

  const intl = useIntl()

  // selectors

  const currentTheme = useSelector(selectors.app.theme)

  // handlers

  const handleResize = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }, [])

  // hooks

  useWindowSize(handleResize)

  // return
  return (
    <>
      <GlobalStyles />
      <Helmet />
      <ThemeProvider theme={themes[currentTheme]}>
        <DefaultHead />
        <Seo
          {...seo}
          url={configuration.app.url}
          lang={intl.locale}
          title="GUCCI FLORA PURIKURA"
          description="Try the Gucci Flora Purikura and share your photos with #FloraFantasy. Spray the fragrance to take a picture with motifs from the latest Gucci Flora Gorgeous Gardenia campaign."
        />
        <Helmet />
        {children}
      </ThemeProvider>
    </>
  )
}

export default App
