import React, { FC } from 'react'

import { Icons } from '../Icon'

import * as SC from './styled'

export type StickersSelectorProps = {
  className?: string
  text: string
  onStickerClick?: (id: string) => void
  onDeleteClick?: () => void
  onUndoClick?: () => void
  stickers: { url: string; id: string }[]
}

const StickersSelector: FC<StickersSelectorProps> = ({
  className,
  text,
  stickers,
  onStickerClick,
  onDeleteClick,
  onUndoClick,
}) => {
  return (
    <SC.StickersSelector className={className}>
      <SC.ActionBar>
        <SC.Text>{text}</SC.Text>
        <SC.ActionIcon icon={Icons.trash} onClick={onDeleteClick} />
        <SC.ActionIcon icon={Icons.undo} onClick={onUndoClick} />
      </SC.ActionBar>
      <SC.Stickers>
        {stickers.map((sticker) => (
          <SC.Sticker
            key={sticker.id}
            src={sticker.url}
            onClick={() => onStickerClick?.(sticker.id)}
          />
        ))}
      </SC.Stickers>
    </SC.StickersSelector>
  )
}

export default StickersSelector
