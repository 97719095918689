import React, { FC, memo, useCallback, useEffect, useRef } from 'react'
import { gsap, Power1 } from 'gsap'

import type { FlowersFinalPixi } from '../../components/FlowersFinalCanvas/FlowersFinalPixi'

import * as SC from './styled'

export type FlowersFinalStepTemplateProps = {
  isPodium: boolean
  retakeLabel: string
  validateLabel: string
  mainPhoto?: string
  flower1Photo?: string
  flower2Photo?: string
  flower3Photo?: string
  onRetakeClick?: () => void
  onPhotoValidate: (url: string) => void
}

const FlowersFinalStepTemplate: FC<FlowersFinalStepTemplateProps> = ({
  isPodium,
  retakeLabel,
  validateLabel,
  mainPhoto,
  flower1Photo,
  flower2Photo,
  flower3Photo,
  onRetakeClick,
  onPhotoValidate,
}) => {
  // REFS

  // pixi
  const $flowersFinalPixi = useRef<FlowersFinalPixi | null>(null)

  // dom
  const $flowersFinalStepTemplate = useRef<HTMLDivElement>(null)
  const $bottomContainer = useRef<HTMLDivElement>(null)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const tl = gsap.timeline({ delay: 0.5 })

    tl.to($flowersFinalStepTemplate.current, {
      duration: 0.8,
      opacity: 1,
      ease: Power1.easeInOut,
    })
      .add(() => {
        $flowersFinalPixi.current?.animateFlowersEnter()
      }, '-=0.4')
      .to(
        $bottomContainer.current,
        {
          duration: 0.8,
          opacity: 1,
          ease: Power1.easeInOut,
        },
        '<+0.6'
      )
  }, [])

  const exitAnimation = useCallback((completeCallback?: () => void) => {
    const tl = gsap.timeline({ onComplete: completeCallback })
    tl.to($flowersFinalStepTemplate.current, {
      duration: 0.5,
      opacity: 0,
      ease: Power1.easeInOut,
    })
  }, [])

  // HANDLERS

  const handleOnFlowersFinalPixi = useCallback(
    (pixi: FlowersFinalPixi) => {
      $flowersFinalPixi.current = pixi
      enterAnimation()
    },
    [enterAnimation]
  )

  const handleOnValidateClick = useCallback(() => {
    exitAnimation(() => {
      const canvas = $flowersFinalPixi.current?.validatePhoto()
      canvas.toBlob(
        (blob: Blob) => {
          const localUrl = window.URL.createObjectURL(blob)
          onPhotoValidate?.(localUrl)
        },
        'image/jpeg',
        0.8
      )
    })
  }, [exitAnimation, onPhotoValidate])

  const handleOnRetakeClick = useCallback(() => {
    exitAnimation(onRetakeClick)
  }, [exitAnimation, onRetakeClick])

  // EFFECTS

  // RETURN

  return (
    <SC.FlowersFinalStepTemplate ref={$flowersFinalStepTemplate}>
      <SC.Box>
        <SC.Canvas
          isPodium={isPodium}
          onFlowersFinalPixi={handleOnFlowersFinalPixi}
          mainPhoto={mainPhoto}
          flower1Photo={flower1Photo}
          flower2Photo={flower2Photo}
          flower3Photo={flower3Photo}
        />
        <SC.BottomContainer ref={$bottomContainer}>
          <SC.Buttons>
            <SC.Button text={retakeLabel} onClick={handleOnRetakeClick} />
            <SC.Button text={validateLabel} onClick={handleOnValidateClick} />
          </SC.Buttons>
        </SC.BottomContainer>
      </SC.Box>
    </SC.FlowersFinalStepTemplate>
  )
}

export default memo(FlowersFinalStepTemplate)
